
import Vue from 'vue';
import TheFooter from '@/components/TheFooter.vue';
import vClickOutside from 'v-click-outside';

export default Vue.extend({
  components: { TheFooter },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  data() {
    return {
      menuBar: false,
    };
  },
  computed: {
    openMenuBar() {
      return this.menuBar ? `display: block` : `display: none`;
    },
  },
  methods: {
    onClickMenuButton() {
      this.menuBar = true;
    },
    onClickMenuCloseButton() {
      this.menuBar = false;
    },
    onclickLinkButton() {
      this.menuBar = false;
    },
    onClickOutside() {
      this.menuBar = false;
    },
  },
});
